.description {
  width: 300px;
  position: absolute;
  top: 0;
  color: white;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.5);
  text-align: left;
  padding-left: 5px;
  padding-right: 5px;
}
.description > a {
  color: white;
}
