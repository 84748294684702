.entry {
  width: 300px;
  position: relative;
  height: 300px;
  margin-bottom: 10px;
  margin-top: 10px;
  margin-left: auto;
  margin-right: auto;
}


